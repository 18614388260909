<template>
  <header>
    <div class="_img">
      <img src="@/assets/img/home/logo.png" alt="" />
    </div>
    <div class="_menu" @click="OnGetState">
      <img v-if="closeState" src="@/assets/img/home/menu.png" alt="" />
      <img v-else src="@/assets/img/home/close.png" alt="" />
    </div>

    <!-- 点击菜单 -->
    <div class="nav" v-if="!closeState">
      <ul>
        <li
          :class="{
            ulColor: iscCurrentRoute('/'),
          }"
          @click="actions(0)"
        >
          首页
        </li>
        <!-- <li
          :class="{
            ulColor: iscCurrentRoute('/eye'),
          }"
          @click="actions(1)"
        >
          裸眼3D
        </li> -->
        <li
          :class="{
            ulColor: iscCurrentRoute('/creative'),
          }"
          @click="actions(3)"
        >
          创意视频
        </li>
        <li>视频内容代运营</li>
        <li
          :class="{
            ulColor: iscCurrentRoute('/simula'),
          }"
          @click="actions(2)"
        >
          三维仿真
        </li>
        <li
          :class="{
            ulColor: iscCurrentRoute('/news'),
          }"
          @click="actions(4)"
        >
          新闻资讯
        </li>
        <li
          :class="{
            ulColor: iscCurrentRoute('/about'),
          }"
          @click="actions(5)"
        >
          关于我们
        </li>
      </ul>
    </div>
  </header>
  <main>
    <router-view></router-view>
  </main>
  <footer>
    <div class="_left">
      <div class="_number">
        <div class="number-box">
          <div class="_service">
            <span class="title">服务咨询</span>
            <span class="num">010-68703766</span>
          </div>
          <div class="_service">
            <span class="title">售后咨询</span>
            <span class="num">010-68703788</span>
          </div>
        </div>
      </div>

      <div class="copyright">
        <div>© Copyright 2008-2021 BJYCXF.COM</div>
        <div>版权所有 京ICP备09080439号</div>
        <div>京公网安备11010802012755号</div>
      </div>
    </div>
    <div class="_right">
      <img src="@/assets/img/home/code.png" alt="" />
    </div>
  </footer>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

onMounted(() => {
  // window.location.reload();
});
// 展开
let closeState = ref(true);
const OnGetState = () => {
  closeState.value = !closeState.value;
};

// 判断当前路由是否是传入的路由地址
const iscCurrentRoute = (path) => router.currentRoute.value.path == path;

const actions = (i) => {
  // position.value = i;
  sessionStorage.setItem("nav", i);
  closeState.value = true;

  if (i == 0) {
    router.push("/");
  } else if (i == 1) {
    router.push("/eye");
  } else if (i == 2) {
    router.push("/simula");
  } else if (i == 3) {
    router.push("/creative");
  } else if (i == 4) {
    router.push("/news");
  } else if (i == 5) {
    router.push("/about");
  }
};
</script>

<style lang="scss" scoped>
header {
  width: 100vw;
  height: 120px;
  margin-bottom: 120px;
  top: 0;
  background: #ffffff;

  position: fixed;
  // background-color: #0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 9px 7px rgba(0, 0, 0, 0.06);
  z-index: 100;
  ._img {
    width: 200px;
    height: 60px;
    margin-left: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  ._menu {
    width: 26px;
    height: 26px;
    margin-right: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav {
    width: 228px;
    height: 500px;
    background: #111114;
    position: absolute;
    right: 30px;
    top: 120px;
    z-index: 1;
    cursor: pointer;
    ul {
      margin-top: 29px;
      color: #ffffff;
      font-family: "Source Han Serif CN";
      text-align: center;
      line-height: 72px;

      li {
        font-size: 24px;
      }
      // font-family: SourceHanSerifCN-Light;
    }
  }
  ul > li:hover {
    color: #e52424;
    font-family: "思源宋体 CN";
  }
  .ulColor {
    color: #e52424;
    font-family: "思源宋体 CN";
  }
}

footer {
  width: 100vw;
  // height: 340px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #ffffff;

  ._left {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: 10px;
    // background-color: #0f0;
    ._number {
      // width: 80%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 16px;
      .number-box {
        display: flex;
        // width: 80%;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        flex-wrap: wrap;
        margin: 15px;
        padding: 20px 0;
        // background-color: #00f;

        flex: 1;
        ._service {
          // margin: 20px;
          display: flex;
          flex-direction: column;
          .title {
            font-size: 12px;
            font-family: "Source Han Serif CN";
          }
          .num {
            font-size: 12px;
          }
        }
      }
    }

    .copyright {
      width: 91%;
      margin: auto;
      font-size: 12px;
      font-family: "Source Han Serif CN";
      padding-bottom: 49px;
      // padding-top: 20px;
    }
  }
  ._right {
    // width: 40%;
    margin-right: 30px;
    // width: 259px;
    // height: 207px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
@media only screen and (min-width: 751px) {
  footer {
    ._left {
      ._number {
        width: 70%;

        .number-box {
          margin-left: 76px;
          ._service {
            .title {
              font-size: 20px;
              font-family: "Source Han Serif CN";
            }
            .num {
              font-size: 28px;
            }
          }
        }
      }

      .copyright {
        // margin: auto;
        width: 100%;
        margin-left: 70px;
        font-size: 20px;
      }
    }
    ._right {
      margin-right: 130px;
      width: 280px;
      height: 207px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
