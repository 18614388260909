import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import '@/assets/font/font.css'

router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    next()
})


createApp(App).use(store).use(router).mount("#app");
