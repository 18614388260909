import { createRouter,createWebHashHistory,createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'



export const childRoutes = [
  {
    path: "/",
    name: "home",
    component: () =>import(/* webpackChunkName: "overview" */ "@/views/Home.vue")
  },
  {
    path: "/eye",
    name: "eye",
    component: () =>
      import(/* webpackChunkName: "overview" */ "@/views/NakedEye.vue"),
  },
  {
    path: "/simula",
    name: "simula",
    component: () =>
      import(/* webpackChunkName: "simula" */ "@/views/Simula.vue"),
  },
  {
    path:"/creative",
    name:"creative",
    component : ()=>
      import(/* webpackChunkName: "creative" */ "@/views/Creative.vue"),
  },
  {
    path:"/news",
    name:'news',
    component : ()=>
      import(/* webpackChunkName: "news" */ "@/views/news.vue"),
  },
  {
    path:"/about",
    name:'about',
    component : ()=>
      import(/* webpackChunkName: "news" */ "@/views/About.vue"),
  },
  {
    path:"/NewsDetail",
    name:'NewsDetail',
    component : ()=>
      import(/* webpackChunkName: "news" */ "@/views/NewsDetail.vue"),
  },
  {
    path:"/CaseDetail",
    name:'CaseDetail',
    component : ()=>
      import(/* webpackChunkName: "news" */ "@/views/CaseDetail.vue"),
  }


]



const routes = [

  {
    path: '/',
    name: 'homes',
    component: HomeView,
    meta: { requiresAuth: true },
    children: childRoutes
  }
]
const router = createRouter({
  // history: createWebHistory('/'),
  history: createWebHistory('/'),
  

  // history: createWebHashHistory(),

  routes,

})
router.beforeEach((to, from, next) => {
  //console.log('路由即将改变：', from.path, '->', to.path);
  var metaDescription= document.querySelector('meta[name="Description"]');
  var metaKeywords = document.querySelector('meta[name="Keywords"]');
  switch(to.path){
    case '/':
        document.title='三维视频|创意视频|3D视频|代运营|数字孪生|展厅多媒体-北京原创先锋';
        metaDescription.setAttribute('content', '原创先锋创立于2008年,16年来，我们一直专注于3D数字视觉领域的设计与创作。 视觉内容为核心，3D技术应用为主线。主要业务包含：3D宣传片,三维视频,三维产品展示,3D工业视频,3D原理视频,3d产品动画,3d动画,3D视频,展厅多媒体,展厅视频，抖音短视频，抖音代运营。');
        metaKeywords.setAttribute('content', '三维视频,裸眼3D视频,3d产品动画,3d动画,3D视频,展厅多媒体,展厅视频,抖音短视频,代运营');
        break;
    case '/creative':
        document.title='创意视频|异形视频|企业宣传视频|品牌营销视频|发布会视频-北京原创先锋';
        metaDescription.setAttribute('content', '原创先锋一直专注于3D数字视觉领域的设计与创作。 视觉内容为核心，3D技术应用为主线。致力于3D视频，互动多媒体内容交互设计,展厅展馆交互内容设计制作。');
        metaKeywords.setAttribute('content', 'CGI技术,三维交互式,创意视频，交互内容设计制作');
      break;
    case '/simula':
        document.title='3D视频|互动多媒体交互内容设计|展厅展馆交互内容设计-原创先锋';
        metaDescription.setAttribute('content', '原创先锋一直专注于3D数字视觉领域的设计与创作。 视觉内容为核心，3D技术应用为主线。致力于3D视频，互动多媒体内容交互设计,展厅展馆交互内容设计制作。');
        metaKeywords.setAttribute('content', '高精度建模,与真实渲染技术,视觉艺术,创意设计,仿真环境');
        break;
    case '/about':
        document.title='3D视频|互动多媒体交互内容设计|展厅展馆交互内容设计-原创先锋';
        metaDescription.setAttribute('content', '原创先锋一直专注于3D数字视觉领域的设计与创作。 视觉内容为核心，3D技术应用为主线。致力于3D视频，互动多媒体内容交互设计,展厅展馆交互内容设计制作。');
        metaKeywords.setAttribute('content', '高精度建模,与真实渲染技术,视觉艺术,创意设计,仿真环境');
        break;
    default:
        document.title='创意视频|三维视频|裸眼3D视频|3D动画视频|代运营|展厅视频|展厅多媒体-北京原创先锋';
        metaDescription.setAttribute('content', '16年来，我们一直专注于3D数字视觉领域的设计与创作。 视觉内容为核心，3D技术应用为主线。 裸眼3D技术，三维仿真技术，与文化 艺术相结合，整合智能硬件、数字化软件、满足各类科技应用。');
        metaKeywords.setAttribute('content', '三维视频,3D原理视频,3d产品动画,3d动画,3D视频,展厅多媒体,展厅视频');
        break;
  }
  next();
});

export default router;
